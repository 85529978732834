/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */

import { makeAutoObservable } from "mobx";
import canvasSize from 'canvas-size';
import Compressor from 'compressorjs';


export interface ICropArea {
    width: number,
    height: number,
    x: number,
    y: number,
}

export class ImageProcessing {
    isDebug: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async generateCroppedImage(imageSrc: string, crop: ICropArea, setCroppedImageBlobUrl: any) {
        this.log('generateCroppedImage(): starting...');
        if (!crop || !imageSrc) {
            this.log('generateCroppedImage(): `crop` or `imageSrc` is empty.');
            this.log('generateCroppedImage(): `crop`: ' + btoa(JSON.stringify(crop)));
            // this.log('generateCroppedImage(): `imageSrc`: ' + btoa(imageSrc));
            return;
        };

        this.log('generateCroppedImage(): loading image to canvas...');

        this.log('generateCroppedImage(): `crop`' + JSON.stringify(crop));
        let canvas = await this.getCroppedImg(imageSrc, crop)

        canvas.toBlob(
            (blob: any) => {
                // 
                const previewUrl = window.URL.createObjectURL(blob);
                this.log('generateCroppedImage(): `previewUrl`: ' + previewUrl);

                //
                var reader = new FileReader()
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    var base64data = reader.result
                    setCroppedImageBlobUrl(base64data)
                    window.URL.revokeObjectURL(previewUrl)
                }
            },
            "image/jpeg",
            1
        )
    }

    createImage(url: string) {
        this.log('createImage(): starting...');
        // this.log('createImage(): `url`: ' + url);
        return new Promise<any>((resolve, reject) => {
            const image = new Image()
            image.addEventListener("load", () => {
                this.log('createImage(): event load...');
                resolve(image);
            })
            image.addEventListener("error", error => {
                this.log('createImage(): event error: ' + JSON.stringify(error));
                reject(error);
            })
            image.setAttribute("crossOrigin", "anonymous") // needed to avoid cross-origin issues on CodeSandbox
            image.src = url;
        })
    }

    compressImage(imgObjectUrl: Blob | File, maxImageDimension: any): Promise<Blob | File> {
        this.log('compressImage(): starting...');
        return new Promise((resolve, reject) => {
            new Compressor(imgObjectUrl, {
                maxWidth: maxImageDimension,
                maxHeight: maxImageDimension,
                success(normalizedFile) {
                    resolve(normalizedFile);
                },
                error(error) {
                    reject(error);
                }
            });
        });
    }

    getImageDimensions(file: any): Promise<{ width: number, height: number }> {
        const imgObjectUrl = URL.createObjectURL(file);
        const img = new Image();

        return new Promise((resolve, reject) => {
            img.onload = () => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;

                URL.revokeObjectURL(imgObjectUrl);
                resolve({ width, height });
            };

            img.onerror = reject;

            img.src = imgObjectUrl;
        });
    }

    async getScaledImg(file: File | Blob): Promise<File | Blob> {
        this.log('getScaledImg(): starting...');
        let fileToUse: File | Blob = file;

        const imageDimensions = await this.getImageDimensions(file);
        const maxCanvasSize = await canvasSize.maxArea({
            usePromise: true,
            useWorker: true
        });

        // Reverse of safeArea = 2 * ((maxSize / 2) * Math.sqrt(2)) in getCroppedImg.js
        const maxImageDimension = Math.floor(
            Math.max(maxCanvasSize.width, maxCanvasSize.height) / Math.sqrt(2)
        );
        this.log('getScaledImg(): `maxImageDimension`: ' + maxImageDimension);
        this.log('getScaledImg(): `imageDimensions.width`: ' + imageDimensions.width);
        this.log('getScaledImg(): `imageDimensions.height`: ' + imageDimensions.height);

        if (
            imageDimensions.width > maxImageDimension ||
            imageDimensions.height > maxImageDimension
        ) {
            fileToUse = await this.compressImage(file, maxImageDimension);
        }

        return fileToUse;
    }

    getRadianAngle(degreeValue: number) {
        this.log('getRadianAngle(): starting...');
        this.log('getRadianAngle(): `degreeValue`: ' + degreeValue);
        let angle = degreeValue * Math.PI / 180;
        this.log('getRadianAngle(): `angle`: ' + angle);
        return angle;
    }

    async getCroppedImg(imageSrc: string, pixelCrop: ICropArea, rotation: number = 0): Promise<HTMLCanvasElement> {
        this.log('getCroppedImg(): starting...');
        this.log('getCroppedImg(): `pixelCrop`: ' + JSON.stringify(pixelCrop));
        this.log('getCroppedImg(): `rotation`: ' + rotation);
        const image = await this.createImage(imageSrc)
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")

        if (ctx == null) {
            this.log('getCroppedImg(): no `ctx`...');
            return canvas;
        }

        this.log('getCroppedImg(): `image.width`: ' + image.width);
        this.log('getCroppedImg(): `image.height`: ' + image.height);

        const maxSize = Math.max(image.width, image.height);
        this.log('getCroppedImg(): `maxSize`: ' + maxSize);

        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
        this.log('getCroppedImg(): `safeArea`: ' + safeArea);


        // https://github.com/ValentinH/react-easy-crop/issues/91#issuecomment-722925200

        // set each dimensions to double largest dimension to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea
        canvas.height = safeArea

        // translate canvas context to a central location on image to allow rotating around the center.
        ctx.translate(safeArea / 2, safeArea / 2)
        ctx.rotate(this.getRadianAngle(rotation))
        ctx.translate(-safeArea / 2, -safeArea / 2)

        // draw rotated image and store data.
        ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)

        const data = ctx.getImageData(0, 0, safeArea, safeArea)

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(
            data,
            0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
            0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
        )

        // resize first
        const ratio = 1.25;
        const height = 300
        const width = height * ratio
        let newCanvas = this.getResizedCanvas(canvas, height, width);
        this.log('getCroppedImg(): `newCanvas`: ' + newCanvas);

        // As Base64 string
        return newCanvas;
    }

    async generateDownload(imageSrc: any, crop: any) {
        this.log('generateDownload(): starting... ');

        if (!crop || !imageSrc) {
            this.log('generateDownload(): `crop` or `imageSrc` is null... ');
            this.log('generateDownload(): `crop`: ' + btoa(JSON.stringify(crop)));
            this.log('generateDownload(): `imageSrc`: ' + btoa(imageSrc));
            return
        }

        const canvas = await this.getCroppedImg(imageSrc, crop)

        canvas.toBlob(
            (blob: any) => {
                const previewUrl = window.URL.createObjectURL(blob)

                const anchor = document.createElement("a")
                anchor.download = "image.jpeg"
                anchor.href = URL.createObjectURL(blob)
                anchor.click()
                window.URL.revokeObjectURL(previewUrl)
            },
            "image/jpeg",
            0.66
        )
    }

    /**
     * @docs https://stackoverflow.com/questions/20555386/canvas-image-to-blob-changing-image-size
     * @returns
     */
    getResizedCanvas(canvas: HTMLCanvasElement, newWidth: number, newHeight: number): HTMLCanvasElement {
        this.log('getResizedCanvas(): starting...');
        this.log('getResizedCanvas(): `newWidth`: ' + newWidth);
        this.log('getResizedCanvas(): `newHeight`: ' + newHeight);
        var tmpCanvas = document.createElement("canvas")
        tmpCanvas.width = newWidth
        tmpCanvas.height = newHeight

        var ctx = tmpCanvas.getContext("2d")
        if (ctx == null) {
            this.log('getResizedCanvas(): `ctx` is `null`');
            return tmpCanvas
        }
        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight)

        return tmpCanvas
    }

    log(message: string) {
        if (this.isDebug) {
            console.log(message);
            // this.mqttDebugLog.publishToMQTT(message);
        }
    }


    base64ToFile = (base64Data: string) => {
        // Decode the base64 string
        let byteString = atob(base64Data.split(',')[1]);


        // Extract the mime type from the base64 string
        let mimeType = base64Data.split(',')[0].match(/:(.*?);/)[1];

        // Convert the byte string to an ArrayBuffer
        let arrayBuffer = new ArrayBuffer(byteString.length);
        let uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        // Create a Blob object from the ArrayBuffer
        let blob = new Blob([uint8Array], { type: mimeType });

        // Convert Blob to File
        let file = new File([blob], "image.jpg", { type: mimeType });

        return file;
    }
}
