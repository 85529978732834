import { makeAutoObservable } from "mobx";

type ConfigValue = string | boolean | number | null;

export class LocalStorageConfig {
    // State
    localStorageKey = 'ktpanabul_config';

    constructor() {
        makeAutoObservable(this);
        this.loadFromLocalStorage();
    }

    // Index signature to allow dynamic property access
    [key: string]: any;

    setConfig = (key: string, value: ConfigValue) => {
        if (value === null) {
            delete this[key];
        } else {
            this[key] = value;
        }
        this.saveToLocalStorage();
    }

    getConfig = (key: string): ConfigValue | null => {
        return this[key] ?? null;
    }

    saveToLocalStorage() {
        // reject if no window 
        if (typeof window === "undefined") {
            console.log('call saveToLocalStorage(): window not found');
            return;
        }
        if (typeof window.localStorage === "undefined") {
            console.log('call saveToLocalStorage(): window.localStorage not found');
            return;
        }


        const config: { [key: string]: ConfigValue } = {};
        for (const key in this) {
            if (this.hasOwnProperty(key) && key !== 'localStorageKey' && typeof this[key] !== 'function') {
                config[key] = this[key];
            }
        }
        window.localStorage.setItem(this.localStorageKey, JSON.stringify(config));
    }

    loadFromLocalStorage() {
        // reject if no window 
        if (typeof window === "undefined") {
            console.log('call loadFromLocalStorage(): window not found');
            return;
        }
        if (typeof window.localStorage === "undefined") {
            console.log('call loadFromLocalStorage(): window.localStorage not found');
            return;
        }


        const storedConfig = window.localStorage.getItem(this.localStorageKey);
        if (storedConfig) {
            const config = JSON.parse(storedConfig);
            for (const key in config) {
                if (config.hasOwnProperty(key)) {
                    this[key] = config[key];
                }
            }
        }
    }
}
