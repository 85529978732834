import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import TimeAgo from "javascript-time-ago"
import id from "javascript-time-ago/locale/id.json"

import "react-toastify/dist/ReactToastify.css"
import "./src/assets/styles/global.css"

import provideStores from "./provide-stores.tsx"

/**
 * Setup time ago
 */
config.autoAddCss = false
TimeAgo.addDefaultLocale(id)

// Load MobX
export const wrapRootElement = provideStores
