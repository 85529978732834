// @docs https://www.dougwaltman.com/blog/2019/mobx-and-gatsby
import React from "react"
import { Provider } from "mobx-react"
import { RootStore } from "./src/stores/RootStore"
import { createContext, useContext } from "react"

// Create a context for the RootStore
const StoreContext = createContext(RootStore);

export const useStore = () => useContext(StoreContext);

// const AppProvider = ({ element }) => {
//     return (
//         <StoreContext.Provider>
//             {element}
//             < /StoreContext.Provider>
//             )
// }

//             export default AppProvider


const MyApp = ({ element }) => {
    return (
        <StoreContext.Provider value={RootStore} >

            {element}
            < /StoreContext.Provider>
            );
};

            export default MyApp;