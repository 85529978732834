import { makeAutoObservable } from "mobx";

export class ModalManagement {
    // State
    isInfoSahabatOneVisible = false;
    isInfoSahabatTwoVisible = false;
    isInfoSahabatPhoneNumberVisible = false;
    isInfoSahabatWhatsAppVisible = false;
    isInfoSahabatTelegramVisible = false;
    isInfoSahabatRegencyVisible = false;
    isInfoSahabatAddressVisible = false;
    isInfoSahabatCoordinateVisible = false;

    isInfoAnabulHandleVisible = false;
    isInfoAnabulPassPhotoVisible = false;
    isInfoAnabulFavMealVisible = false;
    isInfoAnabulHobbyVisible = false;
    isInfoAnabulJobVisible = false;
    isInfoAnabulBloodTypeVisible = false;
    isInfoAnabulVaccineRabiesVisible = false;
    isInfoAnabulVaccineFVRCPVisible = false;
    isInfoAnabulChipNumberVisible = false;

    constructor() {
        makeAutoObservable(this);
    }
}
